import "@babel/polyfill";
import { humburger_menu } from "./modules/humburger-menu";
import { anchor_link } from "./modules/anchor-link";
import { anchor_current } from "./modules/anchor-current";
import { window_size } from "./modules/theme";
import { global_menu } from "./modules/global-menu";
import { ScrollTop } from "./modules/scroll-top";
import { modal } from "./modules/modal";

// window size write to html tag
window_size();

// global menu
global_menu();

// humburger menu
humburger_menu();

// Scroll Top
ScrollTop();

// modal
modal();

// anchor link fix
// anchor_link();
// anchor_current();

// image map
// rwd_image_maps();

// loading use sessionStorage
// loading();

// tab menu
// tab_menus();

// swiper
// cardSwiper();
// mvSwiper();
