/**
 * Global Menu
 */
export function global_menu() {
  document.addEventListener('DOMContentLoaded', function() {
    /**
     * メガメニュー
     */
    const hasMegamenu = document.querySelectorAll('.has-megamenu');
    for (let i = 0; i < hasMegamenu.length; i++) {
      hasMegamenu[i].addEventListener('click', function(event) {
        const currentChooseNavi = this.querySelector('.choose-navi');
        // 他の.choose-naviを閉じる
        const allChooseNavis = document.querySelectorAll('.choose-navi');
        for (let j = 0; j < allChooseNavis.length; j++) {
          if (allChooseNavis[j] !== currentChooseNavi) {
            allChooseNavis[j].style.display = 'none';
          }
        }
        // クリックされた.choose-naviを開く
        currentChooseNavi.style.display = 'block';

        // if (currentChooseNavi.style.display === 'block') {
        //   currentChooseNavi.style.display = 'none';
        // } else {
        //   currentChooseNavi.style.display = 'block';
        // }

        // クリックイベントの伝播を停止
        event.stopPropagation();
      });
    }

    // .choose-navi以外をクリックしたら閉じる
    document.addEventListener('click', function(event) {
      const allChooseNavis = document.querySelectorAll('.choose-navi');
      for (let i = 0; i < allChooseNavis.length; i++) {
        allChooseNavis[i].style.display = 'none';
      }
    });

    /**
     * タブ処理
     */
    const tabButtons = document.querySelectorAll('.tab-head .tabs__tab');

    tabButtons.forEach(tabButton => {
      tabButton.addEventListener('click', function() {
        // クリックされたタブボタンのaria-controls属性の値を取得
        const targetId = this.getAttribute('aria-controls');

        // 対応するタブボディを取得
        const targetTabBody = document.getElementById(targetId);

        // すべてのタブボディを非表示にする
        const tabBodies = document.querySelectorAll('.tab-body');
        tabBodies.forEach(tabBody => {
          tabBody.setAttribute('aria-hidden', 'true');
        });
        tabButtons.forEach(tabButton => {
          tabButton.setAttribute('aria-selected', 'false');
        });

        // 対応するタブボディを表示し、クリックされたタブボタンにaria-selected属性を追加する
        targetTabBody.setAttribute('aria-hidden', 'false');
        this.setAttribute('aria-selected', 'true');
      });
    });
  });
}
