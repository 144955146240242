/**
 * Scroll Top
 */

export function ScrollTop() {
  document.addEventListener('DOMContentLoaded', function() {
    const pagetop = document.getElementById('pagetop');
    // pagetop.style.display = 'none';
    window.addEventListener('scroll', function() {
      if (window.pageYOffset > 1000) {
        pagetop.style.display = 'block';
        pagetop.style.opacity = 1;
      } else {
        pagetop.style.display = 'none';
        pagetop.style.opacity = 0;
      }
    });

    var links = document.querySelectorAll('.c-pagetop a[href^="#"]');
    links.forEach(function(link) {
      link.addEventListener('click', function(event) {
        event.preventDefault();
        var href = this.getAttribute('href');
        var target = document.querySelector(href === '#' ? 'html' : href);
        var distance = target.offsetTop;
        window.scrollTo({
          top: distance,
          behavior: 'smooth'
        });
      });
    });
  });
}
