/**
 * modal
 */

export function modal() {

  document.addEventListener('DOMContentLoaded', function(){
    const modalBtns = document.querySelectorAll('.js-modal-open');
    modalBtns.forEach(function (btn) {
      btn.onclick = function () {
        var modal = btn.getAttribute('data-modal');
        document.getElementById(modal).style.display = "block";
      };
    });
    const closeBtns = document.querySelectorAll('.modal-close');
    closeBtns.forEach(function (btn) {
      btn.onclick = function () {
        var modal = btn.closest('.u-modal');
        modal.style.display = "none";
      };
    });

    window.onclick = function (event) {
      if (event.target.className === 'u-modal') {
        event.target.style.display = "none";
      }
    };
  });
}
