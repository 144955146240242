/**
 * humburger
 */
export function humburger_menu() {
  document.addEventListener('DOMContentLoaded', function() {

    const hamButton = document.getElementById('hamburger-button');
    const humBody   = document.getElementsByTagName('body');
    const hamDrawer = document.getElementById('drawer');

    hamButton.addEventListener('click', function() {
      hamButton.classList.toggle('is-open');
      humBody[0].classList.toggle('is-locked');

      if ( hamDrawer.getAttribute('aria-expanded') == 'false' ) {
        hamDrawer.setAttribute('aria-expanded', true);
      } else {
        hamDrawer.setAttribute('aria-expanded', false);
      }
      if ( hamDrawer.getAttribute('aria-expanded') == 'true' ) {
        hamDrawer.setAttribute('aria-hidden', false);
      } else {
        hamDrawer.setAttribute('aria-hidden', true);
      }

    }, false);

    /**
     * ドロワーメニュー内のアンカーリンク#を押した時に閉じる
     * drawer > .drawer-navi > li > a:href #
     */
    const humDrawerLink = document.querySelector('.drawer-navi a[href^="/#"]');
    if (!humDrawerLink){
      return false;
    }

    humDrawerLink.addEventListener('click', function() {
      hamButton.classList.remove('is-open');
      humBody[0].classList.remove('is-locked');

      if ( hamDrawer.getAttribute('aria-expanded') == 'false' ) {
        hamDrawer.setAttribute('aria-expanded', true);
      } else {
        hamDrawer.setAttribute('aria-expanded', false);
      }
      if ( hamDrawer.getAttribute('aria-expanded') == 'true' ) {
        hamDrawer.setAttribute('aria-hidden', false);
      } else {
        hamDrawer.setAttribute('aria-hidden', true);
      }

    }, false);

  });

  /**
   * ドロップダウン
   */
  document.addEventListener('DOMContentLoaded', function() {
    const dropdownItems = document.querySelectorAll('.c-dropdown-item');

    dropdownItems.forEach(function(item) {
      item.addEventListener('click', function() {
        const parentDropdown = this.parentNode;
        const dropdownMenu = this.nextElementSibling;
        parentDropdown.classList.toggle('is-active');
        // dropdownMenu.classList.toggle('active');
        const expanded = this.getAttribute('aria-expanded') === 'true' || false;
        this.setAttribute('aria-expanded', !expanded);
        const hidden = dropdownMenu.getAttribute('aria-hidden') === 'true' || false;
        dropdownMenu.setAttribute('aria-hidden', !hidden);
      });
    });
  });
}
